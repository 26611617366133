@font-face {
  font-family: 'Poppins';
  font-display: block;
  font-style: normal;
  font-weight: 300;
  src: local('Poppins-Light'),
    url('./assets/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-display: block;
  font-style: normal;
  font-weight: 400;
  src: local('Poppins-Regular'),
    url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-display: block;
  font-style: normal;
  font-weight: 500;
  src: local('Poppins-Medium'),
    url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-display: block;
  font-style: normal;
  font-weight: 600;
  src: local('Poppins-SemiBold'),
    url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-display: block;
  font-style: normal;
  font-weight: 700;
  src: local('Poppins-Bold'),
    url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PassionOne Regular';
  font-display: block;
  font-style: normal;
  font-weight: 700;
  src: local('PassionOne'),
    url('./assets/fonts/PassionOne-Regular.ttf') format('truetype');
}

body,
html {
  /* minimal width of layout */
  min-width: 320px;
}

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Opera bug */
.fa,
.far,
.fas {
  font-family: 'Font Awesome 5 Free' !important;
}

/* Opera bug */
.fab {
  font-family: 'Font Awesome 5 Brands' !important;
}
